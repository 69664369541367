import {createSlice} from "@reduxjs/toolkit";
import moment from "moment/moment";

const dateRangeSlice = createSlice({
    name: "dateRange",
    initialState: {
        startDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
        endDate: moment().add(1, 'year').startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),

        // this flag is needed to check if the date range is set or not 
        // only when user load events on the timeline page for the first time
        isInitialDateRangeSet: false,
    },
    reducers: {
        setDateRange(state, action) {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.isInitialDateRangeSet = true;
        },
        resetDateRangeSet(state) {
            state.isInitialDateRangeSet = false;
        },
    },
});

export const {setDateRange, resetDateRangeSet} = dateRangeSlice.actions;

export default dateRangeSlice.reducer;