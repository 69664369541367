import React from "react";
import _ from "lodash";
import {Tag} from "antd";
import {ApiProjectType} from "../../../constants/constants";
import {useSelector} from "react-redux";

const ProjectsTag = ({projects = []}) => {
    const allProjects = useSelector((state) => state.projects.portfolioProjects);

    const mappedProjects = projects.map((p) => ({...p, ...allProjects.find((allp) => allp.Id === p.Id)}));

    return _.sortBy(mappedProjects, [(a) => a.State === ApiProjectType.Archive]).map((p) => <ProjectTag key={p.Id}
                                                                                                        project={p}/>);
};

export const ProjectTag = ({project}) => {
    return (
        <Tag className="event-project-tag" color={project.State !== ApiProjectType.Archive && project.Color}>
            {project.ShortName || project.Name}
        </Tag>
    );
};

export const ProjectTagForId = ({projectId}) => {
    const project = useSelector((state) => state.projects.portfolioProjects).find((p) => p.Id === projectId);
    return project ? <ProjectTag project={project}/> : null;
};

export default ProjectsTag;
