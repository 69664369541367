import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {getActivePortfolioId} from "../helpers/navigate";
import useChangePortfolio from "./useChangePortfolio";
import usePermissions from "./usePermissions";
import {projectsPath} from "../helpers/paths";
import {ProjectType} from "../constants";

const useActivePortfolio = () => {
    const [isPortfolioLoaded, setPortfolioLoaded] = useState(false);
    const changePortfolio = useChangePortfolio();
    const location = useLocation();

    const portfolios = useSelector((state) => state.projects.portfolios);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const {isStudent} = usePermissions();

    useEffect(() => {
        if (portfolios.length === 0) {
            return;
        }

        const portfolioFromQuery = getActivePortfolioId(location, isStudent);

        const includeLabourCostCalculation = location.pathname === projectsPath[ProjectType.Active]
            || location.pathname === projectsPath[ProjectType.Archive];
      
        if (portfolioFromQuery) {
            if (activePortfolio?.Id !== portfolioFromQuery) {
                changePortfolio(portfolioFromQuery, includeLabourCostCalculation);
            }
        } else if (activePortfolio) {
            changePortfolio(activePortfolio.Id, includeLabourCostCalculation);
        } else {
            changePortfolio(null, includeLabourCostCalculation);
        }

        setPortfolioLoaded(true);
    }, [location, portfolios, activePortfolio]);

    return isPortfolioLoaded;
};

export default useActivePortfolio;