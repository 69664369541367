import React, {useState, useEffect} from "react";
import moment from "moment";
import DateRangeFilter from "../../../Common/Tables/FilterBar/DateRangeFilter";
import {useSelector} from "react-redux";
import {setFilterDateRange} from "../../../../redux/reducers/projects";
import {useDispatch} from "react-redux";

const DateFilter = ({needResetFilter, setFilterHandler}) => {
    const dispatch = useDispatch();
    const filterDateRange = useSelector((state) => state.projects.filterDateRange);

    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        const dates = filterDateRange.length === 0 ? [] : [moment(filterDateRange[0]), moment(filterDateRange[1])];
        setSelectedItems(dates);
        applyFilter(dates);
    }, [filterDateRange]);

    const reset = () => dispatch(setFilterDateRange([]));

    useEffect(() => {
        if (needResetFilter) {
            reset();
        }
    }, [needResetFilter, reset]);

    const onChanged = (filterItems) => {
        const dates = filterItems === null || filterItems.length === 0 ? [] : [filterItems[0].format(), filterItems[1].format()];
        dispatch(setFilterDateRange(dates));
    };

    function applyFilter(filterItems) {
        if (filterItems.length === 0) {
            setFilterHandler(() => true);
        } else {
            const [filterStart, filterEnd] = filterItems;

            setFilterHandler((item) => {
                if (item.ProjectDate === null) return true;

                const projectStart = moment(item.ProjectDate);
                const projectEnd = moment(item.ProjectEndDate);
                if (projectStart < filterStart && projectEnd < filterStart) return false;

                if (projectStart > filterEnd) return false;

                return true;
            });
        }
    }

    return <DateRangeFilter selectedItems={selectedItems} allItemsText="All Dates" onChanged={onChanged} search/>;
};

export default DateFilter;
