import React, {useState} from "react";
import moment from "moment";
import {Button, Col, DatePicker, Dropdown, InputNumber, Menu, Row} from "antd";
import {DownOutlined} from "@ant-design/icons";
import "./Dates.scss";
import {useRef} from "react";
import {useEffect} from "react";
import useDateTimeFormat from "../../../../hooks/useDateTimeFormat";

export const FilterRangeType = Object.freeze({
    AllTime: 0,
    Starting: 1,
    Between: 2,
    TodayForward: 3,
    NextDays: 4,
});

const DateRange = ({onChanged, visible, values}) => {
    const input = useRef();
    const holder = useRef();
    const [days, setDays] = useState();
    const [dateRangeValue, setDateRangeValue] = useState([]);
    const dateTimeFormats = useDateTimeFormat();

    useEffect(() => {
        if (visible) {
            setDays(null);
            if (values) {
                if (values.days != null) {
                    setDays(values.days);
                    setDateRangeValue([moment(), moment().add(values.days, "days")]);
                } else if (values.dates && values.dates.length > 0) {
                    setDateRangeValue(values.dates);
                } else {
                    setDateRangeValue([]);
                }
            }

            setTimeout(() => input.current.focus(), 100);
        } else {
            setDateRangeValue([]);
        }
    }, [visible]);

    useEffect(() => {
        if (days !== null && days >= 0) {
            setDateRangeValue([moment(), moment().add(days, "days")]);
        }
    }, [days]);

    const width = values.RangeType === FilterRangeType.Starting ? 282 : 560;
    const classNames = ["date-range-dates"].join(" ");
    return (
        visible && (
            <Menu selectable={false} style={{width}} className="date-range-menu">
                <Menu.Item
                    key="dates"
                    style={{height: 345}}
                    onClick={(e) => {
                        return true;
                    }}
                    className="date-range-menu-item">
                    <div className="date-range-filter">
                        <div>
                            {values.RangeType === FilterRangeType.Starting && (
                                <DatePicker
                                    value={dateRangeValue[0]}
                                    autoFocus={true}
                                    ref={input}
                                    open={true}
                                    showToday={false}
                                    format={dateTimeFormats.date}
                                    bordered={false}
                                    dropdownClassName={classNames}
                                    getPopupContainer={(trigger) => {
                                        return holder.current;
                                        // return trigger.parentNode.parentNode.childNodes[1];
                                    }}
                                    onChange={(date) => {
                                        onChanged({RangeType: FilterRangeType.Starting, dates: [date]});
                                    }}
                                    renderExtraFooter={() => (
                                        <Row>
                                            <Col span={24}>
                                                <Button
                                                    type="link"
                                                    onClick={() => {
                                                        onChanged({RangeType: FilterRangeType.AllTime}, true);
                                                    }}>
                                                    Date Range...
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                />
                            )}
                            {values.RangeType !== FilterRangeType.Starting && (
                                <DatePicker.RangePicker
                                    value={dateRangeValue}
                                    autoFocus={true}
                                    ref={input}
                                    open={true}
                                    format={dateTimeFormats.date}
                                    bordered={false}
                                    dropdownClassName={classNames}
                                    getPopupContainer={(trigger) => {
                                        return holder.current;
                                    }}
                                    onChange={(dates) => {
                                        onChanged({RangeType: FilterRangeType.Between, dates});
                                    }}
                                    renderExtraFooter={() => (
                                        <Row>
                                            <Col span={14}>
                                                <Button
                                                    type="link"
                                                    onClick={() => {
                                                        onChanged({RangeType: FilterRangeType.TodayForward});
                                                    }}>
                                                    Today Forward
                                                </Button>
                                                <Button
                                                    type="link"
                                                    onClick={() => {
                                                        onChanged({RangeType: FilterRangeType.AllTime});
                                                    }}>
                                                    All Dates
                                                </Button>
                                                <Button
                                                    type="link"
                                                    onClick={() => {
                                                        onChanged({RangeType: FilterRangeType.Starting}, true);
                                                    }}>
                                                    Starting...
                                                </Button>
                                            </Col>
                                            <Col span={6}>
                                                <span>Next</span>&nbsp;
                                                <InputNumber min={0} type="number" value={days}
                                                             onChange={(d) => setDays(d)}></InputNumber>
                                                &nbsp;
                                                <span>Days</span>
                                            </Col>
                                            <Col offset={2} span={2}>
                                                <Button
                                                    type="primary"
                                                    size="small"
                                                    disabled={!(days != null && days >= 0)}
                                                    onClick={() => {
                                                        onChanged({days, RangeType: FilterRangeType.NextDays});
                                                    }}>
                                                    Ok
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                />
                            )}

                            <Button
                                type="link"
                                size="small"
                                className="btn-reset"
                                onClick={() => {
                                    onChanged({RangeType: FilterRangeType.AllTime});
                                }}>
                                Reset
                            </Button>
                        </div>
                        <div ref={holder} className="date-range-holder"></div>
                    </div>
                </Menu.Item>
            </Menu>
        )
    );
};

const isFilteredByDates = (item, filterStart, filterEnd) => {
    if (item.StartDate === null) return true;
    if (filterStart == null) return true;

    const start = moment(item.StartDate).startOf("day");
    const end = moment(item.EndDate).startOf("day");

    if (start < filterStart && end < filterStart) return false;
    if (filterEnd !== null && start > filterEnd) return false;

    return true;
};

function getDate(date) {
    return date ? moment(date) : null;
}

const Dates = ({value, setFilterHandler, disabled, onChange, onChanged}) => {
    const [selectedItem, setSelectedItem] = useState({RangeType: FilterRangeType.AllTime, days: null, dates: []});
    const [selectedDateText, setSelectedDateText] = useState("All Dates");
    const [isOpen, setOpen] = useState(false);

    const dateTimeFormats = useDateTimeFormat();

    useEffect(() => {
        if (value != null) {
            const data = {
                RangeType: value.RangeType,
                days: null,
                dates: [getDate(value.StartDateTime), getDate(value.EndDateTime) || value.EndDate],
            };
            if (value.RangeType === FilterRangeType.NextDays) {
                const days = data.dates[1].diff(data.dates[0], "days");
                data.days = days;
            }

            setSelectedItem(data);
            initDateRange(data, dateTimeFormats);
        }
    }, [value]);

    function initDateRange({RangeType, days = null, dates}, dateTimeFormats) {
        let startDate = null;
        let endDate = null;
        switch (RangeType) {
            case FilterRangeType.TodayForward:
                setSelectedDateText("Today Forward");
                setFilterHandler((item) => isFilteredByDates(item, moment().startOf("day"), null));
                break;

            case FilterRangeType.Between:
                if (days !== null) {
                    startDate = moment().startOf("day");
                    endDate = moment().add(days, "days").startOf("day");
                    setSelectedDateText(days === 0 ? "Today" : `Next ${days} Days`);
                    setFilterHandler((item) => isFilteredByDates(item, startDate, endDate));
                    break;
                }
                if (dates && dates.length === 2 && dates[0] != null) {
                    startDate = dates[0];
                    endDate = dates[1];
                    setSelectedDateText(`${startDate.format(dateTimeFormats.date)} - ${endDate.format(dateTimeFormats.date)}`);
                    setFilterHandler((item) => isFilteredByDates(item, startDate.startOf("day"), endDate.startOf("day")));
                }
                break;
            case FilterRangeType.Starting:
                if (dates && dates[0] != null) {
                    startDate = dates[0];
                    setSelectedDateText(`Starting ${startDate && startDate.format(dateTimeFormats.date)}`);
                    setFilterHandler((item) => isFilteredByDates(item, moment().startOf("day"), null));
                }
                break;
            case FilterRangeType.NextDays:
                if (days !== null) {
                    startDate = moment().startOf("day");
                    endDate = moment().add(days, "days").startOf("day");
                } else if (dates?.length === 2 && dates[0] != null && dates[1] != null) {
                    startDate = dates[0];
                    endDate = dates[1];
                }
                if (startDate && endDate) {
                    days = moment(endDate).diff(startDate, "days");
                    setSelectedDateText(days === 0 ? "Today" : `Next ${days} Days`);
                    setFilterHandler((item) => true);
                }
                break;
            case FilterRangeType.AllTime:
            default:
                setSelectedDateText("All Dates");
                setFilterHandler(() => true);
                break;
        }
        return {
            startDate,
            endDate,
        };
    }

    function onDatedChanged({days = null, dates, RangeType}, doNotSave = false) {
        setSelectedItem({RangeType, days, dates});
        if (!doNotSave) {
            const {startDate, endDate} = initDateRange({days, dates, RangeType}, dateTimeFormats);

            onChange({
                ...value,
                RangeType: RangeType,
                StartDate: startDate,
                EndDate: endDate,
            });
            onChanged();
            setOpen(false);
        }
    }

    return (
        <Dropdown
            disabled={disabled}
            visible={isOpen}
            onClick={() => setOpen(!isOpen)}
            trigger={["click"]}
            overlay={<DateRange values={selectedItem} visible={isOpen} onChanged={onDatedChanged}></DateRange>}
            className="multiple-items-filter">
            <Button type="link" className="btn-multiple-items-filter">
                <span className="filter-text">{selectedDateText}</span>
                <DownOutlined className="filter-text-down"/>
            </Button>
        </Dropdown>
    );
};

export default Dates;
