import React, {useState, useEffect} from "react";
import _ from "lodash";
import {Menu, Layout} from "antd";
import {RightOutlined, LeftOutlined} from "@ant-design/icons";
import logo from "./LogoMark_White.svg";
import logoBig from "./Propared_LMWM_Inline_White.svg";
import {NavLink, useLocation} from "react-router-dom";
import {getRoutes, getRoute, getRootRoute} from "../../helpers";
import "./MainNavigation.scss";
import useUrlWithPortfolioId from "../../hooks/useUrlWithPortfolioId";
import usePermissions from "../../hooks/usePermissions";
import {pathCollection} from "../../helpers/paths";
import {SettingsType, ResourceType, RequirementType} from "../../constants/constants";
import {useSelector} from "react-redux";
import {updateUserSettings, whoAmILight} from "../../services/auth";

const {Sider} = Layout;

const collapsedWidth = 64;
const expandedWidth = 200;

const MainNavigation = () => {
    const route = useLocation();
    const routes = getRoutes();
    const [isExpanded, toggleExpanded] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([route.pathname]);
    const [openedKeys, setOpenedKeys] = useState([]);
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const {canAccessSettings, canAccessSubscription, hasThingsAccess} = usePermissions();
    const expandMainNavigationSettings = useSelector((state) => state.currentUser.user.ExpandMainNavigationSettings);

    useEffect(() => {
        toggleExpanded(expandMainNavigationSettings);
    }, [expandMainNavigationSettings]);

    useEffect(() => {
        const currentPathList = [getRootRoute(route.pathname)?.path, getRoute(route.pathname)?.path];

        setSelectedKeys(isExpanded ? [_.last(currentPathList)] : currentPathList);
        setOpenedKeys([_.first(currentPathList)]);
    }, [route.pathname, isExpanded]);

    const isDisabled = (path) => {
        switch (path) {
            case pathCollection.settingsPath[SettingsType.Access]:
                return !canAccessSettings;
            case pathCollection.settingsPath[SettingsType.Subscription]:
                return !canAccessSubscription;
            default:
                return false;
        }
    };

    const isHidden = (path) => {
        switch (path) {
            case pathCollection.resourcesPath[ResourceType.Item]:
            case pathCollection.resourcesPath[ResourceType.Collection]:
            case pathCollection.resourcesPath[ResourceType.Usage]:
            case pathCollection.requirementsPath[RequirementType.Item]:
                return !hasThingsAccess;
            default:
                return false;
        }
    };

    const onExpanderClick = () => {
        toggleExpanded(!isExpanded);
        updateUserSettings({
            ExpandMainNavigationSettings: !isExpanded,
        }).then(whoAmILight);
    };

    return (<Sider
        width={isExpanded ? expandedWidth : collapsedWidth}
        className={{"navigation-expanded": isExpanded, "main-navigation": true}}
    >
        <div className="menu-logo">
            <Menu theme="dark" className="main-navigation" mode="inline" defaultOpenKeys={openedKeys}
                  selectedKeys={selectedKeys}>
                <Menu.Item key="logo" onClick={() => setSelectedKeys(["/"])}>
                    <NavLink to={urlWithPortfolioId("/")} exact={true} activeClassName="">
                        <img src={isExpanded ? logoBig : logo} alt="Home"
                             height={isExpanded ? "40px" : "24px"}/>
                    </NavLink>
                </Menu.Item>
                <Menu.Item key="expander" onClick={onExpanderClick}>
                    {isExpanded ? <LeftOutlined/> : <RightOutlined/>}
                </Menu.Item>
            </Menu>
        </div>
        <Menu
            theme="dark"
            className="main-navigation main-navigation-content"
            mode="inline"
            defaultOpenKeys={openedKeys}
            selectedKeys={selectedKeys}
        >
            {routes
                .map((route, index) => {
                    if (route.hideButton) {
                        return null;
                    }

                    if (isExpanded && route.childrenRoutes && !isHidden(route.path)) {
                        return (<Menu.SubMenu
                            disabled={isDisabled(route.path)}
                            title={<span><route.icon/>
                                {route.title}</span>}
                            key={`${route.path}_root`}
                        >
                            {route.childrenRoutes.map((childrenRoute) => (<Menu.Item
                                disabled={isDisabled(childrenRoute.path)}
                                key={childrenRoute.path}
                                onClick={() => setSelectedKeys([childrenRoute.path])}
                            >
                                <NavLink to={urlWithPortfolioId(childrenRoute.path)}
                                         title={childrenRoute.title}
                                         exact={childrenRoute.exact}>
                                    {isExpanded ? childrenRoute.title : ""}
                                </NavLink>
                            </Menu.Item>))}
                        </Menu.SubMenu>);
                    } else if (!isHidden(route.path)) {
                        return (<Menu.Item key={route.path} onClick={() => setSelectedKeys([route.path])}
                                           disabled={isDisabled(route.path)}>
                            <NavLink to={urlWithPortfolioId(route.path)} title={route.title}
                                     exact={route.exact}>
                                <route.icon/>
                                {isExpanded ? route.title : ""}
                            </NavLink>
                        </Menu.Item>);
                    }

                    return null;
                })
                .filter((m) => !!m)}
        </Menu>
    </Sider>);
};

export default MainNavigation;
