import React, {useState, useEffect} from "react";
import _ from "lodash";
import moment from "moment";
import {Modal, Form, DatePicker, Divider, message} from "antd";
import {FolderOpenFilled} from "@ant-design/icons";
import {SelectWithFormItem, InputWithFormItem} from "../../Common/Input";
import {useSelector, useDispatch} from "react-redux";
import {openEdit} from "../../../redux/reducers/detailsPanel";
import {ApiProjectType, SidePanelItemType} from "../../../constants";
import {
    getProjectTimetable,
    clone,
    getProject,
    refreshCurrentUserPortfolioProjects,
    refreshAllPortfolioProjects
} from "../../../services/projects";
import "./CloneFromExisting.scss";
import ProjectManagers, {QuickCreateProjectManager} from "../DetailsPanel/ProjectManagers/ProjectManagers";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import ColorPicker, {defaultColor} from "../ColorPicker/ColorPicker";
import {getAccessByProject} from "../../../services/settings";
import {errorFromHttpResponse} from "../../../helpers/error";
import {mapProjectWithBadge} from "../../../helpers/Tags";
import {mapWCToSelect} from "../../../helpers/select";

const Title = () => (
    <>
        <FolderOpenFilled/> New Project from Existing
    </>
);

function getShift(newDate, refDate) {
    if (!refDate) return "0.0:0:00";

    return `${newDate.diff(moment(refDate), "days")}.0:0:00`;
}

const CloneFromExisting = ({isVisible, onClose}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const dateTimeFormats = useDateTimeFormat();

    const projects = useSelector((state) => state.projects.projects).filter((p) =>
        [ApiProjectType.Active, ApiProjectType.Inactive].includes(p.State)
    );
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const [isQuickCreateContactVisible, setIsQuickCreateContactVisible] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [owners, setOwners] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTimeout(() => form.setFieldsValue({}), 100);
    }, [form]);

    useEffect(() => {
        if (isVisible) {
            getAccessByProject(activePortfolio.Id).then((response) => {
                setAdmins(response.AdminMembers);
                setOwners(response.OwnerMembers);
            });
        }
    }, [isVisible]);

    const getTaskDate = (taskId) => tasks.find((t) => t.Id === taskId).StartDate;

    async function handleOk() {
        try {
            setLoading(true);
            const cloneResponse = await cloneProject();

            close();
            
            getProject(cloneResponse.Id)
                .then((project) => dispatch(openEdit({item: project, type: SidePanelItemType.Project})))
                .catch(() => message.error("Server error"));
            await refreshCurrentUserPortfolioProjects();
            await refreshAllPortfolioProjects();
        } catch (err) {
            if (err.errorFields) return;

            const errorText = errorFromHttpResponse(err);
            message.error(errorText);
        } finally {
            setLoading(false);
        }
    }

    function close() {
        onClose();
        form.resetFields();
    }

    const cloneProject = async () => {
        const values = await form.validateFields();
        const cloneResponse = await clone({
            AccountId: activePortfolio.Id,
            Name: values.Name,
            ProjectId: values.SourceProjectId,
            Color: values.Color || defaultColor,
            Shift: getShift(values.NewDate, getTaskDate(values.TaskId)),
            Assistants: values.Assistants || [],
            ShortName: values.ShortName,
        });
        message.success(`${values.Name} was created`);
        return cloneResponse;
    };

    function onProjectChange(val) {
        const project = projects.find((p) => p.Id === val);

        form.setFieldsValue({TaskId: null});
        form.setFieldsValue({Color: project.Color});
        setTasks([]);

        getProjectTimetable(project.Id, true).then((response) => setTasks(_.sortBy(response.Items, "StartDateTime")));
        getAccessByProject(activePortfolio.Id, project.Id).then((response) => {
            form.setFieldsValue({
                Assistants: response.AssistantMembers.map((p) => ({
                    Id: p.Id,
                    ContactId: p.CreatedFromContactId.toString(),
                    MemberAccountId: p.MemberAccountId,
                    EmailAddresses: [p.EmailAddress],
                })),
            });
        });
    }

    const onPmCreated = (newPm) => {
        const assistants = form.getFieldValue("Assistants") || [];
        form.setFieldsValue({
            Assistants: [...assistants, newPm],
        });
    };

    return (
        <>
            <Form form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} layout="horizontal" size="middle">
                <Modal
                    confirmLoading={loading}
                    title={<Title/>}
                    visible={isVisible}
                    maskClosable={false}
                    onOk={handleOk}
                    onCancel={close}
                    okText="Create New Project"
                    className="clone-project-modal">
                    <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                        <Divider orientation="left">Source Project</Divider>
                        <SelectWithFormItem
                            showSearch
                            label="Source Project"
                            name="SourceProjectId"
                            hasFeedback
                            rules={[{required: true, message: "Source Project"}]}
                            style={{width: "100%"}}
                            placeholder="Select a project to clone from"
                            suffixIcon={<FolderOpenFilled/>}
                            onChanged={onProjectChange}
                            options={projects.map(mapProjectWithBadge)}
                        />
                        <SelectWithFormItem
                            showSearch
                            label="Reference Task"
                            name="TaskId"
                            hasFeedback
                            rules={[{required: true, message: "Reference Task"}]}
                            style={{width: "100%"}}
                            options={tasks.map((t) => mapWCToSelect(t, dateTimeFormats))}
                        />
                        <Form.Item label="New Date" name="NewDate" hasFeedback
                                   rules={[{required: true, message: "New Date"}]}>
                            <DatePicker format={dateTimeFormats.date}></DatePicker>
                        </Form.Item>

                        <Divider orientation="left">New Project</Divider>

                        <InputWithFormItem
                            name="Name"
                            label="Project Name"
                            hasFeedback
                            rules={[{required: true, message: "Name"}]}
                            placeholder="Enter a name for your new project"
                        />

                        <InputWithFormItem name="ShortName" label="Short Name"
                                           placeholder="Enter a project short name"/>

                        <Form.Item label="Color" name="Color">
                            <ColorPicker></ColorPicker>
                        </Form.Item>

                        <Form.Item label="Project Managers" name="Assistants">
                            <ProjectManagers
                                admins={admins}
                                owners={owners}
                                setIsQuickCreateContactVisible={setIsQuickCreateContactVisible}></ProjectManagers>
                        </Form.Item>
                    </Form>

                    <QuickCreateProjectManager
                        isQuickCreateContactVisible={isQuickCreateContactVisible}
                        setIsQuickCreateContactVisible={setIsQuickCreateContactVisible}
                        onPmCreated={onPmCreated}></QuickCreateProjectManager>
                </Modal>
            </Form>
        </>
    );
};
export default CloneFromExisting;
