import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {changeActivePortfolioId, getActivePortfolioId} from "../helpers/navigate";
import {setActivePortfolio, setActiveProject, setActiveProjects, setPortfolioLoading} from "../redux/reducers/projects";
import {refreshContacts} from "../services/contacts";
import {refreshAllPortfolioProjects, refreshCurrentUserPortfolioProjects, setupMomentLocale} from "../services/projects";
import {loadTags} from "../services/tags";
import usePermissions from "./usePermissions";
import {loadTimeShortcuts} from "../services/timeShortcuts";
import {loadWorkWeekSettings} from "../services/workWeekSettings";

const useChangePortfolio = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const portfolios = useSelector((state) => state.projects.portfolios);
    const {isStudent, isPermissionsLoaded} = usePermissions();

    const changePortfolio = (portfolioId, includeLabourCostCalculation) => {
    
        if (portfolios.length === 0) {
            return
        }

        const portfolio =
            portfolios.find((p) => p.Id === portfolioId) ||
            portfolios.find((p) => p.Type === 1) || //myOwnPortfolio
            portfolios[0];

        dispatch(setActivePortfolio(portfolio));

        setupMomentLocale(portfolio);

        dispatch(setActiveProject(null));

        dispatch(setActiveProjects([]));

        if (isPermissionsLoaded && getActivePortfolioId(location, isStudent)?.Id !== portfolio.Id) {
            changeActivePortfolioId(portfolio.Id, location, history, isStudent);
        }

        dispatch(setPortfolioLoading(true));

        return Promise.all([
            refreshCurrentUserPortfolioProjects(includeLabourCostCalculation),
            refreshAllPortfolioProjects(),
            refreshContacts()
        ])
            .then(loadTags)
            .then(loadTimeShortcuts)
            .then(loadWorkWeekSettings)
            .finally(() => dispatch(setPortfolioLoading(false)));
    };

    return changePortfolio;
};

export default useChangePortfolio;
