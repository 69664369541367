import React, {useState} from "react";
import {Form, Divider} from "antd";
import {ApiContactType, FilterColumnType} from "../../../constants/constants";
import {useSelector} from "react-redux";
import _ from "lodash";

import Groups from "./Filters/Groups";
import {useEffect} from "react";
import MultipleFilterWithSearchFilter from "../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";
import {getEnumTitle} from "../../../helpers/text";
import {isOptionSelected} from "./Filters/TreeSelectFilter";

const items = [
    {title: "Photos", value: FilterColumnType.Photos, sortRank: 0},
    {title: "Organization", value: FilterColumnType.Organization},
    {title: "Roles", value: FilterColumnType.Roles},
    {title: "Phone", value: FilterColumnType.Phone},
    {title: "Email", value: FilterColumnType.Email},
];

export const TeamBlock = ({form, selectedProjects, setTeamCount, onChanged}) => {
    const [groupsFilter, setGroupsFilter] = useState({handler: null});

    const allTeamMembers = useSelector((state) => state.team.teams) || [];
    const peopleAndOrganizations = allTeamMembers.filter(
        (t) => t.Contact === null || t.Contact?.Type === ApiContactType.Person || t.Contact?.Type === ApiContactType.Organization
    );

    useEffect(() => {
        if (isBlockEnabled) {
            const afterProject = peopleAndOrganizations.filter(
                (item) => item.Contact != null && selectedProjects.includes(item.ProjectId)
            );
            if (_.isFunction(groupsFilter.handler)) {
                setTeamCount(afterProject.filter(groupsFilter.handler).length);
            } else {
                setTeamCount(afterProject.length);
            }
        } else {
            setTeamCount(null);
        }
    }, [groupsFilter, selectedProjects, setTeamCount, peopleAndOrganizations]);

    const isBlockEnabled = form.getFieldValue("IsTeamGroupsEnabled");

    const projects = form.getFieldValue(["Schedule", "Projects"]);

    return (
        <Form
            form={form}
            initialValues={{
                TeamGroups: {
                    TeamGroupColumns: items.map((v) => ({Type: v.value, IsVisible: true})),
                },
            }}
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            layout="horizontal"
            size="middle"
            className="team-member-details">
            <Form.Item label="Groups" name={["TeamGroups", "GroupTags"]} className="ant-form-item-without-validation">
                <Groups
                    onChanged={onChanged}
                    disabled={!isBlockEnabled}
                    scheduleProjects={projects}
                    projectIds={selectedProjects}
                    setFilterHandler={(handler) => {
                        setGroupsFilter({handler});
                    }}></Groups>
            </Form.Item>
            <Divider orientation="left">Appearance</Divider>
            <Form.Item
                label="Columns"
                name={["TeamGroups", "TeamGroupColumns"]}
                className="ant-form-item-without-validation"
                getValueProps={(values = []) => ({
                    selectedItems: (values || [])
                        .filter((v) => v.IsVisible)
                        .map((v) => ({value: v.Type, title: getEnumTitle(FilterColumnType, v.Type)})),
                    value: (values || []).filter((v) => v.IsVisible).map((v) => ({value: v.Type})),
                })}>
                <MultipleFilterWithSearchFilter
                    disabled={!isBlockEnabled}
                    onChanged={(options) => {
                        const val = options.map((o) => o.value);
                        form.setFieldsValue({
                            TeamGroups: {
                                ...form.getFieldValue("TeamGroups"),
                                TeamGroupColumns: items.map((i) => ({
                                    Type: i.value,
                                    IsVisible: isOptionSelected(i.value, val)
                                })),
                            },
                        });
                        onChanged();
                    }}
                    items={items}
                    trigger="click"
                    nothingSelectedText="No Columns"
                    allItemsText="All Columns"></MultipleFilterWithSearchFilter>
            </Form.Item>
        </Form>
    );
};
