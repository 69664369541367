import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {ApiProjectType} from "../../../../constants/constants";
import {mapProjectWithBadgeToTag} from "../../../../helpers/Tags";
import TreeSelectFilter from "./TreeSelectFilter";

const ScheduleIgnoreProjects = ({
                                    value,
                                    onChange,
                                    onChanged,
                                    defaultSelected = [],
                                    disabled,
                                    forwardRef,
                                    isAllProjects,
                                }) => {
    const projects = useSelector((state) => state.projects.projects).filter((p) => p.State === ApiProjectType.Active);
    const inActiveProjects = value?.ScheduleIgnoreProjects?.filter((p) => p.State !== ApiProjectType.Active);
    const portfolioProjects = useSelector((state) => state.projects.portfolioProjects);
    const [selectedItems, setSelectedItems] = useState(value);

    let projectsForSelector = isAllProjects
        ? (disabled ? portfolioProjects : [...projects, ...inActiveProjects])
        : (value?.Projects || []);

    useEffect(() => {
        if (value != null && value.ScheduleIgnoreProjects != null) {
            setSelectedItems(value.ScheduleIgnoreProjects.map((i) => i.Id.toString()));
        }
    }, [value]);


    function onItemsChanged(selectedItems) {
        const filterValues = selectedItems.map((i) => parseInt(i, 10));

        onChange({
            ...value,
            IsAllProjects: isAllProjects,
            ScheduleIgnoreProjects: projectsForSelector.filter((p) => filterValues.includes(p.Id)),
        });
        onChanged();
    }

    const treeData = projectsForSelector.map((p) => ({
        ...mapProjectWithBadgeToTag(p), disabled: false,
    }));

    return (
        <TreeSelectFilter
            hideDivider={true}
            forwardRef={forwardRef}
            disabled={disabled}
            value={selectedItems}
            placeholder="Select projects to ignore"
            defaultSelected={defaultSelected}
            onChanged={onItemsChanged}
            treeData={treeData}
            treeDefaultExpandedKeys={treeData.map((p) => p.value)}
            onSelectAllChange={() => {
            }}
            onResetCompleted={() => {
            }}
        />
    );
};

export default ScheduleIgnoreProjects;