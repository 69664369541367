import api from "../api/api";
import store from "../redux/store";
import {setLines} from "../redux/reducers/labour";

const {dispatch} = store;

export const loadLabourLines = (accountId, timeZoneId, selectedProjectsIds) => {
    const {startDate, endDate} = store.getState().dateRange;

    return api.post(`api/LabourLine/GetByAccount`, {
        accountId,
        timeZoneId,
        startDate,
        endDate,
        projectIds: selectedProjectsIds
    }).then((response) => {
        dispatch(setLines(response));
    });
};

export const getLabourDateRangeWithDefaults = () => {
    const projectIds = store.getState().projects.activeProjects.map(p => p.Id);
    const timeZoneId = store.getState().projects.activePortfolio.TimeZoneId;

    if (projectIds.length === 0) {
        return Promise.resolve();
    }

    return getLabourDateRange(projectIds, timeZoneId);
}

export const getLabourDateRange = (projectIds = [], timeZoneId) => {
    return api.post(`api/LabourLine/GetDateRange?timeZoneId=${encodeURIComponent(timeZoneId)}`, {
        ProjectIds: projectIds,
    }, `getDateRange`)
        .then((resp) => {
            return resp;
        });
}

export const getLabourLine = (id) => api.get(`api/LabourLine/Get?id=${id}`);

export const createLabourLine = (taskId) => api.post(`api/LabourLine/Create?taskId=${taskId}`);
export const createLabourLines = (payload) => api.post(`api/LabourLine/BatchCreate`, payload);
export const updateLabourLine = (payload) => api.post(`api/LabourLine/Update`, payload, `updateLabourLine_${payload.Id}`);
export const createCrewBookings = (taskId) => api.post(`api/LabourLine/CreateCrewBookings?taskId=${taskId}`);

export const deleteLabourLines = (ids) => {
    const accountId = store.getState().projects.activePortfolio?.Id;
    return api.post(`api/LabourLine/BatchRemove?accountId=${accountId}`, ids.map(Id => ({Id})));
};


export const resetRatesLabourLines = (ids) => {
    return api.post(`api/LabourLine/ResetRates`, ids.map(Id => ({Id})));
};