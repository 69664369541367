import * as React from 'react';
import './QuickInfoContentTemplate.scss';
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import moment from "moment/moment";
import {adjustEndDateIfMidnight, isSingleDay, isInvalidTime, formatDuration} from "../../../helpers/time";
import {
    BookingsConflicts, formatLocationName, LabourLinesColumn, LocationConflicts
} from "../../Timeline/TimelineTable";
import {useSelector} from "react-redux";
import {formatTimelineTeamMemberName} from "../../../helpers/text";
import DOMPurify from "dompurify";

const formatEventTime = (props, dateTimeFormats) => {
    const startTime = props.StartTime ? moment(props.StartTime).format(dateTimeFormats.dateTimeWithTime) : null;
    const endTime = props.EndTime ? moment(props.EndTime).format(dateTimeFormats.dateTimeWithTime) : null;

    const isEventTimeNotSet = isInvalidTime(startTime) && isInvalidTime(endTime);

    const startDate = moment(props.StartTime).format(dateTimeFormats.dateLongFormat);
    let endDate = moment(props.EndTime);

    if (isEventTimeNotSet) {
        endDate = adjustEndDateIfMidnight(endDate).format(dateTimeFormats.dateLongFormat);
        return moment(startDate).isSame(endDate, 'day') ? startDate : `${startDate} - ${endDate}`;
    }

    const endDateFormatted = endDate.format(dateTimeFormats.dateLongFormat);
    const startTimeFormatted = moment(props.StartTime).format(dateTimeFormats.time);
    const endTimeFormatted = endDate.format(dateTimeFormats.time);
    const isSingleDayEvent = isSingleDay(startDate, endDate);

    if (startTimeFormatted === endTimeFormatted) {
        return isSingleDayEvent ? `${startDate} (${startTimeFormatted})` : `${startDate} (${startTimeFormatted}) - ${endDateFormatted} (${endTimeFormatted})`;
    }

    return isSingleDayEvent ? `${startDate} (${startTimeFormatted} - ${endTimeFormatted})` : `${startDate} (${startTimeFormatted}) - ${endDateFormatted} (${endTimeFormatted})`;
};

const renderDetails = (data) => {
    const sanitizedHTML = DOMPurify.sanitize(data);
    return <div dangerouslySetInnerHTML={{__html: sanitizedHTML}}/>;
};

export const QuickInfoContentTemplate = (props) => {
    const dateTimeFormats = useDateTimeFormat();
    const formattedEventTime = formatEventTime(props, dateTimeFormats);
    const projects = useSelector((state) => state.projects.portfolioProjects) || [];

    return (
        <div className="quick-info-content">
            <div className="event-content">
                {formattedEventTime && (
                    <div className="row">
                        <div className="column label">Timeframe</div>
                        <div className="column value">{formattedEventTime}</div>
                    </div>
                )}
                {props.ProjectFullName && (
                    <div className="row">
                        <div className="column label">Project</div>
                        <div className="column value">{props.ProjectFullName}</div>
                    </div>
                )}
                {props.Locations && props.Locations.length > 0 && (
                    <div className="row">
                        <div className="column label">Location</div>
                        <div className="column value">
                            {props.Locations.map((location) => (
                                <div key={location?.ContactId}>
                                    <LocationConflicts item={props} projects={projects}
                                                       specificLocationContactId={location?.ContactId}/>{" "}
                                    {formatLocationName(location)}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {props.PeopleOrganizations && props.PeopleOrganizations.length > 0 && (
                    <div className="row">
                        <div className="column label">Contacts</div>
                        <div className="column value contacts-scroll">
                            <BookingsConflicts bookingConflicts={props.BookingConflicts}/>{" "}
                            {(props.PeopleOrganizations || []).map(formatTimelineTeamMemberName).join(", ")}{" "}
                        </div>
                    </div>
                )}
                {props.CategoryTags && props.CategoryTags.length > 0 && (
                    <div className="row">
                        <div className="column label">Categories</div>
                        <div className="column value">{props.CategoryTags.map((x) => x.Name).join(", ")}</div>
                    </div>
                )}
                {props.DepartmentTags && props.DepartmentTags.length > 0 && (
                    <div className="row">
                        <div className="column label">Departments</div>
                        <div className="column value">{props.DepartmentTags.map((x) => x.Name).join(", ")}</div>
                    </div>
                )}
                {props.StatusTag && props.StatusTag.Name && (
                    <div className="row">
                        <div className="column label">Status</div>
                        <div className="column value">{props.StatusTag.Name}</div>
                    </div>
                )}
                {props.Duration && props.Duration !== "00:00:00" && (
                    <div className="row">
                        <div className="column label">Duration</div>
                        <div className="column value">{formatDuration(props.Duration)}</div>
                    </div>
                )}
                {props.LabourLines && props.LabourLines.length > 0 && (
                    <div className="row">
                        <div className="column label">Labour Lines</div>
                        <div className="column value">
                            <LabourLinesColumn labourLines={props.LabourLines}/>
                        </div>
                    </div>
                )}
                {props.Notes && (
                    <div className="row">
                        <div className="column label">Details</div>
                        <div className="column value details-scroll">{renderDetails(props.Notes)}</div>
                    </div>
                )}
            </div>
        </div>
    );
};