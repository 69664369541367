import React, {useState} from "react";
import _ from "lodash";
import {Button, Modal, Form, message} from "antd";
import {ClockCircleFilled, QuestionOutlined, CheckOutlined} from "@ant-design/icons";
import {Various, hasVarious, getTags, withoutVarious, TagIcon} from "../../../helpers/Tags";
import {useSelector} from "react-redux";
import {batchUpdateTasks, batchUpdateBookings} from "../../../services/timeline";
import "./BatchEdit.scss";
import {useEffect} from "react";
import CreateContact from "../../Common/QuickCreate/CreateContact";
import {
    ApiContactType,
    ApiTimelineItemStatus,
    ApiTimelineItemStatusName,
    ApiTimelineItemType,
    SidePanelItemType,
    TaskBatchPropertyFlags,
    BookingBatchPropertyFlags,
} from "../../../constants/constants";
import {useMemo} from "react";
import {formatContactName} from "../../../helpers/text";
import {isFieldDisabled} from "../../Common/BulkEditableField/BulkEditableField";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import EventInputs from "./EventInputs";
import BookingInputs from "./BookingInputs";
import {errorFromHttpResponse, isRequestCanceled} from "../../../helpers/error";
import {getDatesForApi} from "../DetailsPanel/DateAndTime";
import {refreshPositions} from "../../../services/positions";
import {loadTeam} from "../../../services/team";

const Title = ({selected}) => (
    <>
        <ClockCircleFilled/> {`Bulk Edit ${selected.length} Events`}
    </>
);

const TimelineItemStatusIcon = {
    [ApiTimelineItemStatus.None]: <>&nbsp;&nbsp;&nbsp;&nbsp;</>, // <MinusOutlined />,
    [ApiTimelineItemStatus.Tentative]: <QuestionOutlined/>,
    [ApiTimelineItemStatus.Confirmed]: <CheckOutlined/>,
};

function getTeamMembers(selected) {
    const allIds = selected.map((i) => (i.PeopleOrganizations || []).map((tm) => tm.Id.toString()));
    const tags = _.intersection(...allIds);
    const allTags = _.union(...allIds);
    const variousTags = _.difference(allTags, tags);

    if (variousTags.length > 0 || allTags.length !== tags.length) {
        tags.push(Various);
    }

    return tags;
}

function getContacts(selected) {
    const allIds = _.chain(selected)
        .map((i) => (i.PeopleOrganizations || []).filter((tm) => tm.ContactId !== null).map((tm) => tm.ContactId.toString()))
        .value();
    const tags = _.intersection(...allIds);
    const variousTags = _.xor(...allIds);

    if (variousTags.length > 0) {
        tags.push(Various);
    }

    return tags;
}

function getBookingStatus(selected) {
    const statuses = _.uniq(selected.map((i) => i.BookingStatus));
    if (statuses.length === 1) return statuses[0];

    return null;
}

function getTaskStatus(selected) {
    const statuses = _.uniq(selected.map((i) => i.StatusTag?.AccountTagId));
    if (statuses.length === 1) return statuses[0];

    return null;
}

function getLocations(selected) {
    const allIds = _.chain(selected)
        .map((i) => (i.Locations || []).map((tm) => tm.ContactId))
        .value();
    const tags = _.intersection(...allIds);
    const variousTags = _.xor(...allIds);

    if (variousTags.length > 0) {
        tags.push(Various);
    }

    return tags;
}

function getNotes(selected) {
    const notes = _.uniq(selected.map((i) => i.Notes));

    if (notes.length === 1) return notes[0];

    return "";
}

function getFormData(selected, isSingleProject, type) {
    return {
        CategoryTags: type === SidePanelItemType.Event ? getTags(selected, "CategoryTags") : [],
        DepartmentTags: type === SidePanelItemType.Event ? getTags(selected, "DepartmentTags") : [],
        TeamMemberIds: isSingleProject ? getTeamMembers(selected) : [],
        TeamMemberContactIds: getContacts(selected),
        Locations: getLocations(selected),
        EnableLocationConflicts: false,
        BookingStatus: getBookingStatus(selected),
        StatusTagId: getTaskStatus(selected),
        Notes: getNotes(selected),
    };
}

const prepareDatesForBatchUpdate = (formValue) => {
    const dates = formValue.Date?.length === 2 ? formValue.Date : [null, null];
    const times = formValue.Time?.length === 2 ? formValue.Time : [null, null];
    return getDatesForApi({
        startDate: dates[0],
        endDate: dates[1],
        startTime: times[0],
        endTime: times[1],
    });
};

const BulkEdit = ({selected, type}) => {
    const dateTimeFormats = useDateTimeFormat();

    const [isVisible, setVisible] = useState(false);
    const [isContentVisible, setContentVisible] = useState(false);

    const [loading, setLoading] = useState(false);
    const [timeFrameFormat, setTimeFrameFormat] = useState(dateTimeFormats.date);
    const [isQuickCreateContactVisible, setIsQuickCreateContactVisible] = useState(false);
    const [isQuickCreateOrgVisible, setIsQuickCreateOrgVisible] = useState(false);
    const selectedTimeZone = useSelector((state) => state.timeline.selectedTimeZone);

    const projectIds = useMemo(() => _.uniq(selected.filter(i => i && i.ProjectId).map((i) => i.ProjectId)).filter((x) => x) || [], [selected]);

    const isSingleProject = projectIds.length === 1;

    const timelineItems = useSelector((state) => state.timeline.events);
    let workCalls = isSingleProject ? timelineItems.filter((i) => i.ProjectId === projectIds[0]) : [];

    const projectCategories = useSelector((state) =>
        _.chain(projectIds)
            .map((projectId) => state.tags.projectCategories[projectId])
            .flatten()
            .uniqBy((x) => x?.AccountTagId)
            .filter((i) => !_.isNil(i))
            .value()
    );
    const categories = useSelector((state) => state.tags.categories || []).filter(
        (t) => projectCategories.map((x) => x.AccountTagId).indexOf(t.AccountTagId) === -1
    );

    const projectDepartments = useSelector((state) =>
        _.chain(projectIds)
            .map((projectId) => state.tags.projectDepartments[projectId])
            .flatten()
            .uniqBy((x) => x?.AccountTagId)
            .filter((i) => !_.isNil(i))
            .value()
    );

    const departments = useSelector((state) => state.tags.departments || []).filter(
        (t) => projectDepartments.map((x) => x.AccountTagId).indexOf(t.AccountTagId) === -1
    );

    const [form] = Form.useForm();

    const positions = useSelector((state) => state.labour.positions);

    useEffect(() => {
        if (positions.length === 0) refreshPositions();
    }, []);

    useEffect(() => {
        if (isVisible) form.setFieldsValue(getFormData(selected, isSingleProject, type));
    }, [selected, isVisible]);

    function showModal() {
        setVisible(true);
        setContentVisible(true);
    }

    const onOk = async () => {
        setLoading(true);
        const formValue = form.getFieldsValue();
        try {
            if (type === SidePanelItemType.Event) {
                await updateTasks(formValue);
            }

            if (type === SidePanelItemType.Booking) {
                await updateBookings(formValue);
            }

            close();
        } catch (err) {
            if (!isRequestCanceled(err)) {
                const errorText = errorFromHttpResponse(err);
                message.error(errorText);
                console.error(err);
            }
        } finally {
            setLoading(false);
        }
    };

    const updateTasks = (formValue) => {
        let teamMemberContactIds = [];
        teamMemberContactIds = !isFieldDisabled(isSingleProject ? "TeamMemberIds" : "TeamMemberContactIds", form)
            ? withoutVarious(form.getFieldValue("TeamMemberContactIds")).map((x) => parseInt(x, 10))
            : [];

        const teamMemberIds = !isFieldDisabled("TeamMemberIds", form)
            ? withoutVarious(form.getFieldValue("NewTeamMemberIds")).map((x) => parseInt(x, 10))
            : [];

        const getPropertyFlagValue = (key) => {
            return !isFieldDisabled(key, form) ? TaskBatchPropertyFlags[key] : 0;
        };

        const locationIds = !isFieldDisabled("Locations", form) ? withoutVarious(formValue.Locations) : [];
        const taskBatchPropertyFlags = _.keys(TaskBatchPropertyFlags).reduce((prev, key) => prev | getPropertyFlagValue(key), 0);
        const datesDto = prepareDatesForBatchUpdate(formValue);
        var taskPayload = {
            ItemsToUpdate: selected.map((i) => ({Id: i.Id, ItemType: ApiTimelineItemType.Task})),
            Dto: {
                Name: !isFieldDisabled("Name", form) ? formValue.Name : undefined,
                NewLocationContactIds: locationIds,
                StartDate: !isFieldDisabled("Date", form) ? datesDto.startDate : null,
                EndDate: !isFieldDisabled("Date", form) ? datesDto.endDate : null,
                StartTime: !isFieldDisabled("Time", form) ? datesDto.startTime : null,
                EndTime: !isFieldDisabled("Time", form) ? datesDto.endTime : null,
                NewTeamMemberContactIds: teamMemberContactIds,
                TeamMemberIds: teamMemberIds,
                DepartmentTags: withoutVarious(formValue.DepartmentTags),
                CategoryTags: withoutVarious(formValue.CategoryTags),

                StatusTagId: !isFieldDisabled("StatusTagId", form) ? formValue.StatusTagId : null,
                Bookings: [],
                IsFavorite: !isFieldDisabled("IsFavorite", form) ? formValue.IsFavorite || false : null,
                Notes: !isFieldDisabled("Notes", form) ? formValue.Notes : null,
            },
            TaskBatchPropertyFlags: taskBatchPropertyFlags,
            LeaveVariousCategories: hasVarious(formValue.CategoryTags),
            LeaveVariousDepartments: hasVarious(formValue.DepartmentTags),

            LeaveVariousTeamMembers: hasVarious(formValue.TeamMemberIds) || hasVarious(formValue.TeamMemberContactIds),
            LeaveVariousLocations: hasVarious(formValue.Locations),
            EnableLocationConflicts: !isFieldDisabled("LocationAvailability", form) ? formValue.LocationAvailability : null,
            TimeZoneId: selectedTimeZone,
        };

        return batchUpdateTasks(taskPayload).then((res) => {
            if (!isFieldDisabled("TeamMemberIds", form) && isSingleProject) {
                loadTeam(projectIds[0]);
            }
            if (_.some(res, "TaskHasNonSyncedBookings")) {
                message.info(
                    "Some of these events include bookings with unique dates & times. The dates & times of these bookings were not changed."
                );
            }
        });
    };

    const updateBookings = (formValue) => {
        const teamMemberContactIds = form.getFieldValue("TeamMemberContactIds");
        const contactId = _.isString(teamMemberContactIds)
            ? parseInt(teamMemberContactIds, 10)
            : withoutVarious(teamMemberContactIds)[0];
        const bookingBatchPropertyFlags = _.keys(BookingBatchPropertyFlags).reduce((prev, key) => {
            return !isFieldDisabled(key, form) ? prev | BookingBatchPropertyFlags[key] : prev;
        }, 0);

        const datesDto = prepareDatesForBatchUpdate(formValue);

        const payload = {
            Dto: {
                StartDate: !isFieldDisabled("Date", form) ? datesDto.startDate : null,
                EndDate: !isFieldDisabled("Date", form) ? datesDto.endDate : null,
                StartTime: !isFieldDisabled("Time", form) ? datesDto.startTime : null,
                EndTime: !isFieldDisabled("Time", form) ? datesDto.endTime : null,
                IsAllDay: null,
                ContactId: contactId,
                BookingStatus: !isFieldDisabled("BookingStatus", form) ? formValue.BookingStatus : null,
                Notes: !isFieldDisabled("Notes", form) ? formValue.Notes : null,
                PositionId: formValue.PositionId,
            },
            BookingIdToUpdate: selected.map((i) => i.Id),
            BookingBatchPropertyFlags: bookingBatchPropertyFlags,
            LeaveVariousDepartments: false,
            LeaveVariousSyncOptions: false,
            TimeZoneId: selectedTimeZone,
        };

        return batchUpdateBookings(payload);
    };

    function onCancel() {
        close();
    }

    function close() {
        setContentVisible(false);
        setVisible(false);
        form.resetFields();
    }

    const bookingStatusOptions = [ApiTimelineItemStatus.None, ApiTimelineItemStatus.Confirmed, ApiTimelineItemStatus.Tentative].map(
        (status) => ({
            value: status,
            label: (
                <>
                    {TimelineItemStatusIcon[status]} {ApiTimelineItemStatusName[status]}
                </>
            ),
        })
    );

    const statuses = useSelector((state) => state.tags.statuses || []);
    const statusOptions = statuses.map((status) => ({
        value: status.AccountTagId,
        title: status.Name,
        label: (
            <>
                <TagIcon icon={status.Icon}/> {status.Name}
            </>
        ),
    }));

    const allContacts = useSelector((state) => state.contacts.contacts);
    const locations = allContacts.filter((c) => c.Type === ApiContactType.Space);
    const locationRawGroups = _.groupBy(locations, (l) => l.ParentId);

    const locationGroups = _.keys(locationRawGroups).map((key) => ({
        group: formatContactName(allContacts.find((c) => c.Id === parseInt(key))) || "- No Place -",
        items: locationRawGroups[key].map((c) => ({
            value: c.Id,
            label: formatContactName(c),
        })),
    }));

    const isAllDayBookings = !_.some(selected, (b) => !b?.IsAllDay);

    const getDateFormatForBookings = () => (isAllDayBookings ? dateTimeFormats.date : dateTimeFormats.dateTime);

    return (
        <>
            <Form
                form={form}
                labelCol={{span: 6}}
                wrapperCol={{span: 18}}
                layout="horizontal"
                size="middle"
                initialValues={{IsAllDay: true}}>
                <Button type="primary" onClick={showModal}>
                    Edit
                </Button>
                <Modal
                    confirmLoading={loading}
                    title={<Title selected={selected}/>}
                    visible={isVisible}
                    maskClosable={false}
                    onOk={onOk}
                    okText="Ok"
                    onCancel={onCancel}>
                    {isContentVisible && (
                        <>
                            {type === SidePanelItemType.Event && (
                                <EventInputs
                                    form={form}
                                    isSingleProject={isSingleProject}
                                    projectDepartments={projectDepartments}
                                    departments={departments}
                                    projectCategories={projectCategories}
                                    categories={categories}
                                    projectIds={projectIds}
                                    setIsQuickCreateOrgVisible={setIsQuickCreateOrgVisible}
                                    setIsQuickCreateContactVisible={setIsQuickCreateContactVisible}
                                    setTimeFrameFormat={setTimeFrameFormat}
                                    timeFrameFormat={timeFrameFormat}
                                    locationGroups={locationGroups}
                                    statusOptions={statusOptions}
                                    timeFormat={dateTimeFormats.time}
                                    dateFormat={dateTimeFormats.date}
                                    selectedTimeZone={selectedTimeZone}
                                />
                            )}
                            {type === SidePanelItemType.Booking && (
                                <BookingInputs
                                    form={form}
                                    isSingleProject={isSingleProject}
                                    workCalls={workCalls}
                                    projectIds={projectIds}
                                    positions={positions}
                                    setIsQuickCreateOrgVisible={setIsQuickCreateOrgVisible}
                                    setIsQuickCreateContactVisible={setIsQuickCreateContactVisible}
                                    statusOptions={bookingStatusOptions}
                                    dateTimeFormats={dateTimeFormats}
                                    selectedTimeZone={selectedTimeZone}
                                    timeFormat={dateTimeFormats.time}
                                    dateFormat={dateTimeFormats.date}
                                    eventDateFormat={getDateFormatForBookings()}
                                />
                            )}
                        </>
                    )}
                </Modal>
            </Form>
            <CreateContact
                apiContactType={ApiContactType.Person}
                isVisible={isQuickCreateContactVisible}
                onClose={() => setIsQuickCreateContactVisible(false)}
                onContactCreated={(contactId) => {
                    const values = form.getFieldValue("TeamMemberContactIds") || [];
                    const teamMembers = form.getFieldValue("TeamMemberIds") || [];
                    const newTmId = `contact_${contactId}`;
                    form.setFieldsValue({
                        TeamMemberContactIds: [...values, contactId.toString()],
                        TeamMemberIds: [...teamMembers, newTmId],
                    });
                }}
            />
            <CreateContact
                apiContactType={ApiContactType.Organization}
                isVisible={isQuickCreateOrgVisible}
                onClose={() => setIsQuickCreateOrgVisible(false)}
                onContactCreated={(contactId) => {
                    const values = form.getFieldValue("TeamMemberContactIds") || [];
                    const teamMembers = form.getFieldValue("TeamMemberIds") || [];
                    const newTmId = `contact_${contactId}`;
                    form.setFieldsValue({
                        TeamMemberContactIds: [...values, contactId.toString()],
                        TeamMemberIds: [...teamMembers, newTmId],
                    });
                }}
            />
        </>
    );
};
export default BulkEdit;
